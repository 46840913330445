import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from './../../environments/environment';
export const InterceptorSkipHeader = new HttpHeaders({ 'skipInterceptor': '' });
const USER_KEY = 'andAngAppUser';
const TOKEN_KEY = 'andAngApptoken';

export interface User {
  id: any,
  name: any,
  email: any,
  role: any
}

@Injectable({
  providedIn: 'root'
})
export class ApiServiceService {
  public currentUser: User;
  public APIURL: any = environment.baseUrl + '/api/';
  public authenticationState = new BehaviorSubject(false);
  public loginEvent: EventEmitter<any> = new EventEmitter();
  public itemsPerPage: number = 10;

  public tinymcakey = 'j6yxus09nsvgnrl6mkdptsxxzyri4mlgo6u4zlq27db8by6g';
  public tinymcasettings = {
    icons: 'material',
    skin: 'borderless',
    plugins: 'wordcount',
    menubar: true,
    min_height: 350
  };


  constructor(
    private router: Router,
    private http: HttpClient
  ) {
    this.checkToken();
  }
  checkToken() {
    if (localStorage.getItem(TOKEN_KEY) && localStorage.getItem(USER_KEY)) {
      this.currentUser = JSON.parse(localStorage.getItem(USER_KEY));
      this.authenticationState.next(true);
    }
  }
  isAuthenticated() {
    return this.authenticationState.value;
  }
  //---------------- Login ---------------------
  login(fromData): Observable<any> {
    return this.http.post<any>(this.APIURL + 'auth/login', JSON.stringify(fromData))
      .pipe(
        tap(res => {
          console.log("Login => ", res);
          if (res['status']) {
            localStorage.setItem(TOKEN_KEY, JSON.stringify(res['tokendata']['access_token']));
            this.authenticationState.next(true);
            //----------------------------
            let udata = res['userdetails'];
            this.loginEvent.emit(+udata['role']);
            let data = {
              id: udata['_id'],
              email: udata['email'],
              name: udata['name'],
              role: udata['role'],
            }
            this.currentUser = data;
            localStorage.setItem(USER_KEY, JSON.stringify(data));
          }
        }),
        catchError(this.handleError('login', { status: false }))
      );
  }
  //----------------Logout-----------------------
  clearStorage() {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(USER_KEY);
    this.currentUser = null;
    this.authenticationState.next(false);
  }
  logout(): Observable<any> {
    return this.http.post<any>(this.APIURL + 'auth/logout', {});
  }
  //------------------Add Partner--------------
  addPartner(fdata): Observable<any> {
    return this.http.post<any>(this.APIURL + 'admin/add-partner', fdata, { headers: InterceptorSkipHeader })
      .pipe(
        tap(res => this.log('Add Partner')),
        catchError(this.handleError('Add Partner', []))
      );
  }
  //------------------Add Partner--------------
  getAllPartner(): Observable<any> {
    return this.http.get<any>(this.APIURL + 'admin/get-all-partner')
      .pipe(
        tap(res => this.log('Get All Partner')),
        catchError(this.handleError('Get All Partner', []))
      );
  }
  //------------------Add Single Partner--------------
  getSinglePartner(pid): Observable<any> {
    return this.http.post<any>(this.APIURL + 'admin/get-partner-details', { id: pid })
      .pipe(
        tap(res => this.log('Get Single Partner')),
        catchError(this.handleError('Get Single Partner', []))
      );
  }
  //------------------Update Partner--------------
  updatePartner(fdata): Observable<any> {
    return this.http.post<any>(this.APIURL + 'admin/update-partner', fdata, { headers: InterceptorSkipHeader })
      .pipe(
        tap(res => this.log('update Partner')),
        catchError(this.handleError('update Partner', []))
      );
  }
  //------------------Get Country--------------
  getCountry(): Observable<any> {
    return this.http.post<any>(this.APIURL + 'shop/country', {})
      .pipe(
        tap(res => this.log('Get Country')),
        catchError(this.handleError('Get Country', []))
      );
  }
  //------------------Get State--------------
  getState(cid): Observable<any> {
    return this.http.post<any>(this.APIURL + 'shop/state', { "id": +cid })
      .pipe(
        tap(res => this.log('Get State')),
        catchError(this.handleError('Get State', []))
      );
  }

  //------------------Get City--------------
  getCity(sid): Observable<any> {
    return this.http.post<any>(this.APIURL + 'shop/city', { "id": +sid })
      .pipe(
        tap(res => this.log('Get City')),
        catchError(this.handleError('Get City', []))
      );
  }
  //------------------Add Shop--------------
  addShop(fdata): Observable<any> {
    return this.http.post<any>(this.APIURL + 'admin/add-shop', fdata)
      .pipe(
        tap(res => this.log('Add Shop')),
        catchError(this.handleError('Add Shop', []))
      );
  }
  //------------------Get All Shop--------------
  getAllShop(): Observable<any> {
    return this.http.get<any>(this.APIURL + 'admin/get-all-shop')
      .pipe(
        tap(res => this.log('Get All Shop')),
        catchError(this.handleError('Get All Shop', []))
      );
  }
  //------------------Get Single Shop--------------
  getSingleShop(sid): Observable<any> {
    return this.http.post<any>(this.APIURL + 'admin/get-shop-details', { id: sid })
      .pipe(
        tap(res => this.log('Get Single Shop')),
        catchError(this.handleError('Get Single Shop', []))
      );
  }
  //------------------Update Shop--------------
  editShop(fdata): Observable<any> {
    return this.http.post<any>(this.APIURL + 'admin/update-shop', fdata)
      .pipe(
        tap(res => this.log('Update Shop')),
        catchError(this.handleError('Update Shop', []))
      );
  }
  //------------------Get Partner Shop--------------
  getPartnerShop(): Observable<any> {
    return this.http.post<any>(this.APIURL + 'partner/shops-list', { "id": this.currentUser.id })
      .pipe(
        tap(res => this.log('Get Partner Shop')),
        catchError(this.handleError('Get Partner Shop', []))
      );
  }
  //------------------Get Registered Emails--------------
  getRegisteredEmails(): Observable<any> {
    return this.http.post<any>(this.APIURL + 'partner/customers', { "partnerId": this.currentUser.id })
      .pipe(
        tap(res => this.log('Get Registered Emails')),
        catchError(this.handleError('Get Registered Emails', []))
      );
  }
  //------------------Delete Customer Email--------------
  deletePartnerCustomer(customerId): Observable<any> {
    return this.http.delete<any>(this.APIURL + 'partner/delete-customer/' + customerId)

  }

  deletePartnerCustomers(customerId): Observable<any> {
    return this.http.post<any>(this.APIURL + 'partner/delete-customers', { "customerId": customerId })

  }
  //------------------Change Password--------------
  changePass(fdata): Observable<any> {
    return this.http.post<any>(this.APIURL + 'auth/changepwd', fdata)
      .pipe(
        tap(res => this.log('Change Password')),
        catchError(this.handleError('Change Password', []))
      );
  }
  changePartnerPass(fdata): Observable<any> {
    return this.http.post<any>(this.APIURL + 'admin/reset-partner-pwd', fdata)
      .pipe(
        tap(res => this.log('Change Partner Password')),
        catchError(this.handleError('Change Partner Password', []))
      );
  }
  //------------------Save Customer--------------
  saveCustomer(fdata): Observable<any> {
    return this.http.post<any>(this.APIURL + 'customer/confirm', fdata)
      .pipe(
        tap(res => this.log('Save Customer')),
        catchError(this.handleError('Save Customer', []))
      );
  }

  //------------------Customer Chart--------------
  customerChart(fdata): Observable<any> {
    return this.http.post<any>(this.APIURL + 'customer/chart', fdata)
      .pipe(
        tap(res => this.log('Customer Chart')),
        catchError(this.handleError('Customer Chart', []))
      );
  }
  //------------------Email Count--------------
  getEmailCount(): Observable<any> {
    return this.http.post<any>(this.APIURL + 'admin/email-count', {})
      .pipe(
        tap(res => this.log('Email Count')),
        catchError(this.handleError('Email Count', []))
      );
  }

  //------------------Update Api Url--------------
  updateApiUrl(fdata): Observable<any> {
    return this.http.post<any>(this.APIURL + 'admin/refreshkey', fdata)
      .pipe(
        tap(res => this.log('Update Api Url')),
        catchError(this.handleError('Update Api Url', []))
      );
  }

  //------------------Add Country--------------
  addCountry(fdata): Observable<any> {
    return this.http.post<any>(this.APIURL + 'admin/add-country', fdata)
      .pipe(
        tap(res => this.log('Add Country')),
        catchError(this.handleError('Add Country', []))
      );
  }
  //------------------Edit Country--------------
  editCountry(fdata): Observable<any> {
    return this.http.post<any>(this.APIURL + 'admin/edit-country', fdata)
      .pipe(
        tap(res => this.log('Edit Country')),
        catchError(this.handleError('Edit Country', []))
      );
  }
  //------------------Edit State--------------
  editState(fdata): Observable<any> {
    return this.http.post<any>(this.APIURL + 'admin/edit-state', fdata)
      .pipe(
        tap(res => this.log('Edit State')),
        catchError(this.handleError('Edit State', []))
      );
  }
  //------------------Edit City--------------
  editCity(fdata): Observable<any> {
    return this.http.post<any>(this.APIURL + 'admin/edit-city', fdata)
      .pipe(
        tap(res => this.log('Edit City')),
        catchError(this.handleError('Edit City', []))
      );
  }
  //------------------Delete Country--------------
  deletecountry(id): Observable<any> {
    return this.http.post<any>(this.APIURL + 'admin/delete-country', { "id": id })
      .pipe(
        tap(res => this.log('Delete Country')),
        catchError(this.handleError('Delete Country', []))
      );
  }
  //------------------Delete State--------------
  deletestate(id): Observable<any> {
    return this.http.post<any>(this.APIURL + 'admin/delete-state', { "id": id })
      .pipe(
        tap(res => this.log('Delete State')),
        catchError(this.handleError('Delete State', []))
      );
  }
  //------------------Delete City--------------
  deletecity(id): Observable<any> {
    return this.http.post<any>(this.APIURL + 'admin/delete-city', { "id": id })
      .pipe(
        tap(res => this.log('Delete City')),
        catchError(this.handleError('Delete City', []))
      );
  }
  //------------------Get All State--------------
  getAllState(itemsPerPage): Observable<any> {
    return this.http.post<any>(this.APIURL + 'admin/getallstate', { 'itemsPerPage': itemsPerPage })
      .pipe(
        tap(res => this.log('Get State')),
        catchError(this.handleError('Get State', []))
      );
  }
  //------------------Get All State pagination--------------
  getAllStateByUrl(url, itemsPerPage): Observable<any> {
    return this.http.post<any>(url, { 'itemsPerPage': itemsPerPage, 'url': url })
      .pipe(
        tap(res => this.log('Get State')),
        catchError(this.handleError('Get State', []))
      );
  }
  //------------------Get All City--------------
  getAllCity(itemsPerPage): Observable<any> {
    return this.http.post<any>(this.APIURL + 'admin/getallcity', { 'itemsPerPage': itemsPerPage })
      .pipe(
        tap(res => this.log('Get city')),
        catchError(this.handleError('Get city', []))
      );
  }
  //------------------Get All City By State Id--------------
  getAllCityByState(itemsPerPage, id): Observable<any> {
    return this.http.post<any>(this.APIURL + 'admin/getallcitybystateid', { 'itemsPerPage': itemsPerPage, 'id': id })
      .pipe(
        tap(res => this.log('Get city')),
        catchError(this.handleError('Get city', []))
      );
  }
  //------------------Get All City  By State Id pagination--------------
  getAllCityByUrlState(url, itemsPerPage, id): Observable<any> {
    return this.http.post<any>(url, { 'itemsPerPage': itemsPerPage, 'url': url, 'id': id })
      .pipe(
        tap(res => this.log('Get city')),
        catchError(this.handleError('Get City', []))
      );
  }
  //------------------Get All State By Country Id--------------
  getAllStateByCountry(itemsPerPage, id): Observable<any> {
    return this.http.post<any>(this.APIURL + 'admin/getallstatebycountryid', { 'itemsPerPage': itemsPerPage, 'id': id })
      .pipe(
        tap(res => this.log('Get State')),
        catchError(this.handleError('Get State', []))
      );
  }
  //------------------Get All State  By Country Id pagination--------------
  getAllStateByUrlCountry(url, itemsPerPage, id): Observable<any> {
    return this.http.post<any>(url, { 'itemsPerPage': itemsPerPage, 'url': url, 'id': id })
      .pipe(
        tap(res => this.log('Get State')),
        catchError(this.handleError('Get State', []))
      );
  }
  //------------------Get All City pagination--------------
  getAllCityByUrl(url, itemsPerPage): Observable<any> {
    return this.http.post<any>(url, { 'itemsPerPage': itemsPerPage, 'url': url })
      .pipe(
        tap(res => this.log('Get city')),
        catchError(this.handleError('Get City', []))
      );
  }
  //------------------Add State--------------
  addState(fdata): Observable<any> {
    return this.http.post<any>(this.APIURL + 'admin/add-state', fdata)
      .pipe(
        tap(res => this.log('Add State')),
        catchError(this.handleError('Add State', []))
      );
  }

  //------------------Add City--------------
  addCity(fdata): Observable<any> {
    return this.http.post<any>(this.APIURL + 'admin/add-city', fdata)
      .pipe(
        tap(res => this.log('Add City')),
        catchError(this.handleError('Add City', []))
      );
  }
  //------------------Get Single Country--------------
  getSingleCountry(pid): Observable<any> {
    return this.http.post<any>(this.APIURL + 'admin/get-single-country', { id: pid })
      .pipe(
        tap(res => this.log('Get Single Country')),
        catchError(this.handleError('Get Single Country', []))
      );
  }
  //-------------------Get Single State--------------
  getSingleState(pid): Observable<any> {
    return this.http.post<any>(this.APIURL + 'admin/get-single-state', { id: pid })
      .pipe(
        tap(res => this.log('Get Single State')),
        catchError(this.handleError('Get Single State', []))
      );
  }
  //-------------------Get Single State--------------
  getSingleCity(pid): Observable<any> {
    return this.http.post<any>(this.APIURL + 'admin/get-single-city', { id: pid })
      .pipe(
        tap(res => this.log('Get Single City')),
        catchError(this.handleError('Get Single City', []))
      );
  }
  //------------------Add Terms & Conditions--------------
  addTermsCondtion(fdata): Observable<any> {
    return this.http.post<any>(this.APIURL + 'admin/add-terms-condition', fdata)
      .pipe(
        tap(res => this.log('Add Terms-Condition')),
        catchError(this.handleError('Add Terms-Condition', []))
      );
  }
  //------------------Get Terms & Condition--------------
  getTermsCondition(): Observable<any> {
    return this.http.get<any>(this.APIURL + 'admin/show-terms-condition')
      .pipe(
        tap(res => this.log('Get Terms-Condition')),
        catchError(this.handleError('Get Terms-Condition', []))
      );
  }

  getSettingsForPartner(): Observable<any> {
    return this.http.get<any>(this.APIURL + `partner/fetch-app-settings/${this.currentUser.id}`)
      .pipe(
        tap(res => this.log('Get Settings For Partner')),
        catchError(this.handleError('Get Settings For Partner', []))
      );
  }
  updateSettingsForPartner(fdata): Observable<any> {
    return this.http.post<any>(this.APIURL + `partner/save-app-settings/${this.currentUser.id}`, fdata, { headers: InterceptorSkipHeader })
      .pipe(
        tap(res => this.log('updateSettingsForPartner')),
        catchError(this.handleError('updateSettingsForPartner', []))
      );
  }

  getGlobalSettings(): Observable<any> {
    return this.http.get<any>(this.APIURL + `admin/get-global-settings`)
      .pipe(
        tap(res => this.log('updateGlobalSettings')),
        catchError(this.handleError('updateGlobalSettings', []))
      );
  }
  updateGlobalSettings(fdata): Observable<any> {
    return this.http.post<any>(this.APIURL + `admin/add-global-settings`, fdata)
      .pipe(
        tap(res => this.log('updateGlobalSettings')),
        catchError(this.handleError('updateGlobalSettings', []))
      );
  }
  //=============================================================
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // Send the error to remote logging infrastructure
      console.error(error); // log to console instead
      // Setter job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);
      // Let the app keep running by returning an empty result.
      //-----------------------------------------------------
      // return of(result as T);
      return of(error.error as T);
      //-----------------------------------------------------
    };
  }
  /** Log a HeroService message with the MessageService */
  private log(message: string) {
    console.log(message);
  }
  //------------------Get Registered Emails in admin--------------
  getRegisteredEmailsAdmin(getRegisteredEmailsAdmin): Observable<any> {
    return this.http.post<any>(this.APIURL + 'admin/get-partner-shop-customers', getRegisteredEmailsAdmin)
      .pipe(
        tap(res => this.log('Get Registered Emails')),
        catchError(this.handleError('Get Registered Emails', []))
      );
  }

  //------------------Get Registered Emails in admin--------------
  getShop(pid): Observable<any> {
    return this.http.post<any>(this.APIURL + 'admin/get-partner-shop-list', { "Id": pid })
      .pipe(
        tap(res => this.log('Get Registered Emails')),
        catchError(this.handleError('Get Registered Emails', []))
      );
  }

  deleteCustomer(customerId): Observable<any> {
    return this.http.delete<any>(this.APIURL + 'admin/delete-customer/' + customerId)
      .pipe(
        tap(res => this.log('Delete customer email')),
        catchError(this.handleError('Get Registered Emails', []))
      );
  }
  deleteCustomers(customerId): Observable<any> {
    return this.http.post<any>(this.APIURL + 'admin/delete-partner-customers', { "customerId": customerId })

  }
  //------------------get Deleted Partner--------------
  getAllDeletedAccount(): Observable<any> {
    return this.http.get<any>(this.APIURL + 'admin/get-all-deleted-emails')
      .pipe(
        tap(res => this.log('Get All Deleted Emails')),
        catchError(this.handleError('Get All Deleted Emails', []))
      );
  }
  restoreCustomer(customerId): Observable<any> {
    return this.http.get<any>(this.APIURL + 'admin/restore-customer/' + customerId)
      .pipe(
        tap(res => this.log('restore customer email')),
        catchError(this.handleError('Get Registered Emails', []))
      );
  }
  restoreCustomers(customerId): Observable<any> {
    return this.http.post<any>(this.APIURL + 'admin/restore-customers', { "customerId": customerId })
      .pipe(
        tap(res => this.log('restore customers email')),
        catchError(this.handleError('Get Registered Emails', []))
      );
  }

  deletedCustomers(customerId): Observable<any> {
    return this.http.post<any>(this.APIURL + 'admin/delete-customers', { "customerId": customerId })
      .pipe(
        tap(res => this.log('delete customers email')),
        catchError(this.handleError('Get Registered Emails', []))
      );
  }
}