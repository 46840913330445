import { Component } from '@angular/core';
import { navItemsAdmin } from '../../_navAdmin';
import { navItemsPartner } from '../../_navPartner';
import { Router } from '@angular/router';
import { ApiServiceService } from '../../services/api-service.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.css']
})
export class DefaultLayoutComponent {
  public sidebarMinimized = false;
  public navItems = navItemsPartner;
  public currYear = new Date().getFullYear();
  constructor(
    private router: Router,
    public apiService: ApiServiceService
  ) {
    this.apiService.loginEvent.subscribe(res => {
      if (+res == 1) {
        this.navItems = navItemsAdmin;
      } else if (+res == 2) {
        this.navItems = navItemsPartner;
      }
    })
    if (this.apiService.currentUser && (+this.apiService.currentUser.role == 1)) {
      this.navItems = navItemsAdmin;
    } else if (this.apiService.currentUser && (+this.apiService.currentUser.role == 2)) {
      this.navItems = navItemsPartner;
    }
  }
  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }
  changePassword() {
    if (this.apiService.currentUser && (+this.apiService.currentUser.role == 1)) {
      this.router.navigate(['/admin/change-password']);
    } else if (this.apiService.currentUser && (+this.apiService.currentUser.role == 2)) {
      this.router.navigate(['/partner/change-password']);
    }
  }
  logout() {
    this.apiService.logout().subscribe(res => {
      console.log("Logout = ", res);
      this.apiService.clearStorage();
      this.router.navigate(['/login']);
    }, err => {
      this.apiService.clearStorage();
      this.router.navigate(['/login']);
      console.log("Error = ", err);
    });
  }
}
