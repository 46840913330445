import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError, finalize } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ApiServiceService } from '../services/api-service.service';
export const InterceptorSkip = 'skipInterceptor';
const TOKEN_KEY = 'andAngApptoken';

@Injectable()
export class HttpinterceptorInterceptor implements HttpInterceptor {

  constructor(
    private authService: ApiServiceService,
    private router: Router,
  ) { }
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // --------------A WAY, Authentication by setting header with token value-------------
    if (localStorage.getItem(TOKEN_KEY)) {
      let sdata = JSON.parse(localStorage.getItem(TOKEN_KEY));
      request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + sdata) });
    }
    // --------------Authentication by setting header value-----------------
    if (request.headers && request.headers.has(InterceptorSkip)) {
      const headers = request.headers.delete(InterceptorSkip);
      request = request.clone({ headers });
    } else {
      if (!request.headers.has('Content-Type')) {
        request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
      }
      request = request.clone({ headers: request.headers.set('Accept', 'application/json') });
    }
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // console.log('event=>', event);             
        }
        return event;
      }), catchError((error: HttpErrorResponse) => {
        // console.error(error); 
        if (error.status === 401) {
          // auto logout if 401 response returned from api
          this.authService.clearStorage();
          this.router.navigate(['/login']);
        }
        if (error.status === 404) {
          this.router.navigate(['/404']);
        }
        const msg = error.error.message || error.statusText;
        return throwError(error);
      }), finalize(() => { })
    );
  }
}
