import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/common/login/login.component';
import { AdminAuthGuard } from './auth/admin-auth.guard';
import { PartnerAuthGuard } from './auth/partner-auth.guard';
import { SaveCustomerComponent } from './views/common/save-customer/save-customer.component';

export const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login Page'
    }
  },
  {
    path: 'confirm/:cus_key/:partner_logo/:shopId',
    component: SaveCustomerComponent,
    data: {
      title: 'Confirm'
    }
  },
  //-----------------------------
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    // data: {
    //   title: 'Home'
    // },
    children: [
      {
        path: 'admin',
        loadChildren: () => import('./views/admin/admin.module').then(m => m.AdminModule),
        canActivateChild: [AdminAuthGuard], canActivate: [AdminAuthGuard]
      },
      {
        path: 'partner',
        loadChildren: () => import('./views/partner/partner.module').then(m => m.PartnerModule),
        canActivateChild: [PartnerAuthGuard], canActivate: [PartnerAuthGuard]
      }
    ]
  },
  { path: '**', component: P404Component }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
