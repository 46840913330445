import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiServiceService } from '../../../services/api-service.service';
import { environment } from './../../../../environments/environment';

@Component({
  selector: 'app-save-customer',
  templateUrl: './save-customer.component.html',
  styleUrls: ['./save-customer.component.css']
})
export class SaveCustomerComponent implements OnInit {
  public isResponse: boolean = false;
  public msg: any = null;
  public logo: any = null;
  public baseUrl = environment.baseUrl;
  constructor(
    private actRoute: ActivatedRoute,
    public apiService: ApiServiceService
  ) {
    console.log("Save Customer");
    let cKey = this.actRoute.snapshot.paramMap.get('cus_key');
    let shopId = this.actRoute.snapshot.paramMap.get('shopId');
    this.logo = this.actRoute.snapshot.paramMap.get('partner_logo');
    if (cKey && shopId) {
      this.apiService.saveCustomer({ key: cKey, 'shopId':shopId }).subscribe((res) => {
        console.log("get Single Partner =>", res);
        this.isResponse = true;
        if (res.status) {
          this.msg = res.message;
        } else if (typeof (res.message) == 'object') {
          if ('message' in res.message) {
            this.msg = res.message;
          }
        } else {
          this.msg = res.message;
        }
      }, err => {
        this.msg = "Something went wrong";
        this.isResponse = true;
      });
    }
  }


  ngOnInit(): void {

  }

}
