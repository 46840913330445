import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiServiceService } from '../../../services/api-service.service';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-dashboard',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  public loginForm: FormGroup;
  public passwordType = "password";
  public formSubmit: boolean = false;
  public isRequesting: boolean = false;
  public errMsg: any = null;


  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    public apiService: ApiServiceService
  ) {
    this.apiService.authenticationState.subscribe(state => {
      if (state) {
        if (this.apiService.currentUser && (+this.apiService.currentUser.role == 1)) {
          this.router.navigate(['/admin/dashboard']);
        } else if (this.apiService.currentUser && (+this.apiService.currentUser.role == 2)) {
          this.router.navigate(['/partner/dashboard']);
        }
      }
    });
  }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      email: new FormControl('', Validators.compose([
        Validators.required
      ])),
      password: new FormControl('', Validators.compose([
        Validators.required
      ])),
    });
  }
  showPassword() {
    this.passwordType = "text";
    setTimeout(() => {
      this.passwordType = "password";
    }, 3000);
  }
  loginUser(value, isValid) {
    this.errMsg = null;
    console.log(value)
    if (isValid && !this.isRequesting) {
      this.isRequesting = true;
      this.apiService.login(value).subscribe(res => {
        console.log("Login = ", res);
        if (res['status']) {
          this.isRequesting = false;
          let role = +res['userdetails']['role'];
          console.log("role =", role);
          if (role) {
            this.apiService.loginEvent.emit(role);
            if (role == 1) {
              this.router.navigate(['/admin/dashboard']);
            } else {
              this.router.navigate(['/partner/dashboard']);
            }
          }
        } else {
          this.isRequesting = false;
          this.errMsg = res['error'];
        }
      }, err => {
        this.isRequesting = false;
        this.errMsg = "Something went wrong";
        console.log("Error = ", err);
      })
    } else {
      this.formSubmit = true;
    }
  }
  validation_messages = {
    'email': [
      { type: 'required', message: 'Email is required' }
    ],
    'password': [
      { type: 'required', message: 'Password is required' }
    ]
  };
}
