import { INavData } from '@coreui/angular';
export const navItemsPartner: INavData[] = [
  {
    name: 'Dashboard',
    url: '/partner/dashboard',
    icon: 'icon-speedometer'
  },
  {
    name: 'Shop',
    url: '/partner/shop',
    icon: 'fa fa-map-marker'
  },
  {
    name: 'Registered Emails',
    url: '/partner/registered-emails',
    icon: 'fa fa-envelope'
  },
  {
    name: 'Settings',
    url: '/partner/settings',
    icon: 'fa fa-cogs'
  },
]