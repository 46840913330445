import { INavData } from '@coreui/angular';
export const navItemsAdmin: INavData[] = [
  {
    name: 'Dashboard',
    url: '/admin/dashboard',
    icon: 'icon-speedometer'
  },
  {
    title: true,
    name: 'Partner Management'
  },
  {
    name: 'Add Partner',
    url: '/admin/add-partner',
    icon: 'icon-user-follow'
  },
  {
    name: 'Manage Partner',
    url: '/admin/manage-partner',
    icon: 'icon-pencil'
  },
  {
    title: true,
    name: 'Shop Management'
  },
  {
    name: 'Add Shop',
    url: '/admin/add-shop',
    icon: 'fa fa-map-marker'
  },
  {
    name: 'Manage Shop',
    url: '/admin/manage-shop',
    icon: 'icon-pencil'
  },
  {
    title: true,
    name: 'Customer Management'
  },
  {
    name: 'Registered Emails',
    url: '/admin/registered-emails',
    icon: 'fa fa-envelope'
  }, {
    name: 'Deleted Emails',
    url: '/admin/deleted-emails',
    icon: 'fa fa-envelope'
  },
  {
    title: true,
    name: 'Location Management'
  },
  {

    name: 'Manage Location',
    url: '/admin/manage-country',
    icon: 'fa fa-location-arrow'

  },
  {
    title: true,
    name: 'Others'
  },
  {
    name: 'Settings',
    url: '/admin/settings',
    icon: 'fa fa-cogs'
  },
  // {
  //   name: 'Add Terms Condition',
  //   url: '/admin/add-terms-condition',
  //   icon: 'fa fa-file'
  // }
];
